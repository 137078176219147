import { defineStyleConfig } from "@chakra-ui/react";

export const Style = defineStyleConfig({
  baseStyle: {
    fontFamily: "Poppins, sans-serif",
    cursor: "default",
    color: "#F9FAFB",
    lineHeight: "121%",
  },
  variants: {
    loginTitle: {
      fontSize: { base: "32px", lg: "72px", xl: "2.3333vw" },
      fontWeight: "550",
      lineHeight: { base: "40px", xl: "56px" },
    }, //temporary variant
    h1: {
      fontSize: { base: "52px", md: "5vw" },
      fontWeight: "700",
      lineHeight: "121%",
      letterSpacing: { base: "1.04px", md: "1.44px" },
    },
    h2: {
      fontSize: { base: "22px", md: "2.2222vw" },
      fontWeight: "700",
      lineHeight: "121%",
      letterSpacing: { base: "0.44px", md: "0.64px" },
    },
    h3: {
      fontSize: { base: "18px", md: "1.66vw" },
      fontWeight: "700",
      lineHeight: { base: "normal", md: "28px" },
      letterSpacing: { base: "0.36px", md: "0.48px" },
    },
    h4: {
      fontSize: { base: "18px", md: "1.39vw" },
      fontWeight: "700",
      lineHeight: { base: "normal", md: "140%" },
    },
    subtitle: {
      fontSize: { base: "18px", md: "1.9444vw" },
      fontWeight: "400",
      lineHeight: "121%",
      letterSpacing: { base: "0.36px", md: "0.56px" },
    },
    body: {
      fontSize: { base: "16px", md: "1.39vw", xl: "1.1111vw" },
      fontWeight: "400",
      lineHeight: "121%",
      letterSpacing: { base: "0.36px", md: "0.4px" },
    },

    paragraph: {
      fontSize: { base: "18px", md: "1.9444vw" },
      fontWeight: "700",
      lineHeight: "121%",
    },
    main_btn: {
      fontSize: { base: "16px", md: "1.3889vw" },
      fontWeight: "700",
      lineHeight: "121%",
    },
    footer: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: { base: "121%", md: "18px" },
    },
  },
});

export const TextStyle = {
  key: "Text",
  style: Style,
};
