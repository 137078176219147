import { StyleFunctionProps, extendTheme } from "@chakra-ui/react";
import { ButtonStyle } from "src/components/Button/buttonStyle";
import { TextStyle } from "src/components/Text/textStyle";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const breakpoints = {
  base: "320px",
  sm: "390px",
  md: "768px",
  "2md": "1024px",
  "3md": "1280px",
  lg: "1440px",
  "2lg": "1920px",
  xl: "2400px",
  "2xl": "3840px",
};

const scrollbarStyles = {
  "&::-webkit-scrollbar": {
    display: { base: "none", md: "none" },
    width: "0",
    borderRadius: "1000px",
    backgroundColor: "gray",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "1000px",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "1000px",
    backgroundColor: "black",
  },
};

const styles = {
  global: (props: StyleFunctionProps) => ({
    "*": scrollbarStyles,
    "html, body": {
      scrollBehavior: "smooth",
      color: "Black.1",
      // bg: mode('white', 'gray.800')(props),
      touchAction: "pan-x pan-y !important", // Disable pinch-zoom and other gestures
    },
    a: {
      WebkitAppearance: "none",
      WebkitTapHighlightColor: "transparent",
      WebkitTouchCallout: "none",
      WebkitUserSelect: "none",
      KhtmlUserSelect: "none",
      MozUserSelect: "none",
      MsUserSelect: "none",
      UserSelect: "none",
    },
    svg: {
      ":active, :focus": {
        backgroundColor: "none",
      },
    },
    button: {
      _focusVisible: { boxShadow: "none", outline: "none" },
    },
  }),
};

const theme = extendTheme({
  config,
  styles,
  breakpoints,
  components: {
    [TextStyle.key]: TextStyle.style,
    [ButtonStyle.key]: ButtonStyle.style,
  },
  colors: {
    BgColor: "#030512",
    ButtonBlue: "#2876FE",
    White700: "#A3A3A3",
    Orange: "#DD7C66",
    Purple: "#322C3B",
    Black: {
      1: "#141414",
      2: "#333",
      3: "#7A7A7A",
    },
    Gray: {
      1: "#F2F2F2",
      2: "#EDEDED",
      3: "#E0E0E0",
      4: "#D6D6D6",
      5: "#B8B8B8",
      6: "#8F8F8F",
    },
  },
  fonts: {
    heading: "var(--font-robotoFlex)",
    body: "var(--font-robotoFlex)",
  },
});

export default theme;
