import { Flex, HStack, Icon, Image, Text, VStack } from "@chakra-ui/react";
import ava from "../../images/ava.webp";
import isabella from "../../images/isabella.webp";
import sophia from "../../images/sophia.webp";

export const Reviews = () => {
  const reviewsData = [
    {
      image: sophia,
      title: "Sophia",
      description:
        "I'm seriously impressed with how much you can customize in this game. Getting to pick my own era and adventure style just took the whole experience to another level of cool.",
      date: new Date(2022, 2, 1),
    },
    {
      image: isabella,
      title: "Isabella",
      description:
        "The way my character could look just like me was a game-changer. It felt like I was truly a part of the story, influencing the plot with every choice I made.",
      date: new Date(2022, 2, 7),
    },
    {
      image: ava,
      title: "Ava",
      description:
        "I've never experienced a game that adapts to my decisions in such a detailed way. Every session feels unique, and it's thrilling to see how my choices impact the game's outcome.",
      date: new Date(2022, 2, 16),
    },
  ];

  return (
    <Flex
      w={{ base: "100%", lg: "83vw" }}
      p={{ base: "32px 0 32px", md: "60px 0 60px" }}
      direction={"column"}
      gap={{ base: "16px", md: "60px" }}
    >
      <Text zIndex={500} variant={"h2"}>
        Reviews
      </Text>
      <Flex
        direction={{ base: "column", "3md": "row" }}
        gap={{ base: "16px", md: "32px" }}
        w={{ base: "100%", lg: "83vw" }}
        h={"100%"}
        bgColor={"#030512"}
        borderRadius={"16px"}
      >
        {reviewsData.map((review) => (
          <Flex
            w={"100%"}
            direction={"column"}
            justify={"center"}
            alignItems={"center"}
            gap={"16px"}
            p={"24px"}
            boxShadow={"0px 0px 80px 10px #9747FF"}
            borderRadius={"16px"}
            border={"1px solid rgba(193, 223, 224, 0.4)"}
          >
            <Flex w={"100%"} gap={"8px"} justifyContent={"space-between"}>
              <HStack justifyContent={"flex-start"} alignItems={"flex-start"}>
                <Image w={"43px"} borderRadius={"50%"} src={review.image} />
                <VStack>
                  <Text variant={"body"} fontWeight={700}>
                    {review.title}
                  </Text>
                  <Icon w={"66px"} h={"12px"} viewBox="0 0 66 12">
                    <path
                      d="M3.5332 11.333C3.25977 11.123 3.20117 10.7764 3.34766 10.3467L4.32422 7.45117L1.83887 5.67383C1.46289 5.40527 1.29688 5.09277 1.40918 4.75586C1.52148 4.43359 1.83398 4.27246 2.29297 4.27734L5.33984 4.30176L6.26758 1.3916C6.40918 0.947266 6.64844 0.698242 7 0.698242C7.34668 0.698242 7.58594 0.947266 7.72754 1.3916L8.65527 4.30176L11.6973 4.27734C12.1611 4.27246 12.4736 4.43359 12.5859 4.76074C12.6982 5.09277 12.5322 5.40527 12.1562 5.67383L9.6709 7.45117L10.6475 10.3467C10.7939 10.7764 10.7354 11.123 10.4619 11.333C10.1836 11.5479 9.83691 11.4795 9.45605 11.2061L7 9.39453L4.53418 11.2061C4.1582 11.4795 3.81152 11.5479 3.5332 11.333Z"
                      fill="#EE8834"
                    />
                    <path
                      d="M16.5332 11.333C16.2598 11.123 16.2012 10.7764 16.3477 10.3467L17.3242 7.45117L14.8389 5.67383C14.4629 5.40527 14.2969 5.09277 14.4092 4.75586C14.5215 4.43359 14.834 4.27246 15.293 4.27734L18.3398 4.30176L19.2676 1.3916C19.4092 0.947266 19.6484 0.698242 20 0.698242C20.3467 0.698242 20.5859 0.947266 20.7275 1.3916L21.6553 4.30176L24.6973 4.27734C25.1611 4.27246 25.4736 4.43359 25.5859 4.76074C25.6982 5.09277 25.5322 5.40527 25.1562 5.67383L22.6709 7.45117L23.6475 10.3467C23.7939 10.7764 23.7354 11.123 23.4619 11.333C23.1836 11.5479 22.8369 11.4795 22.4561 11.2061L20 9.39453L17.5342 11.2061C17.1582 11.4795 16.8115 11.5479 16.5332 11.333Z"
                      fill="#EE8834"
                    />
                    <path
                      d="M29.5332 11.333C29.2598 11.123 29.2012 10.7764 29.3477 10.3467L30.3242 7.45117L27.8389 5.67383C27.4629 5.40527 27.2969 5.09277 27.4092 4.75586C27.5215 4.43359 27.834 4.27246 28.293 4.27734L31.3398 4.30176L32.2676 1.3916C32.4092 0.947266 32.6484 0.698242 33 0.698242C33.3467 0.698242 33.5859 0.947266 33.7275 1.3916L34.6553 4.30176L37.6973 4.27734C38.1611 4.27246 38.4736 4.43359 38.5859 4.76074C38.6982 5.09277 38.5322 5.40527 38.1562 5.67383L35.6709 7.45117L36.6475 10.3467C36.7939 10.7764 36.7354 11.123 36.4619 11.333C36.1836 11.5479 35.8369 11.4795 35.4561 11.2061L33 9.39453L30.5342 11.2061C30.1582 11.4795 29.8115 11.5479 29.5332 11.333Z"
                      fill="#EE8834"
                    />
                    <path
                      d="M42.5332 11.333C42.2598 11.123 42.2012 10.7764 42.3477 10.3467L43.3242 7.45117L40.8389 5.67383C40.4629 5.40527 40.2969 5.09277 40.4092 4.75586C40.5215 4.43359 40.834 4.27246 41.293 4.27734L44.3398 4.30176L45.2676 1.3916C45.4092 0.947266 45.6484 0.698242 46 0.698242C46.3467 0.698242 46.5859 0.947266 46.7275 1.3916L47.6553 4.30176L50.6973 4.27734C51.1611 4.27246 51.4736 4.43359 51.5859 4.76074C51.6982 5.09277 51.5322 5.40527 51.1562 5.67383L48.6709 7.45117L49.6475 10.3467C49.7939 10.7764 49.7354 11.123 49.4619 11.333C49.1836 11.5479 48.8369 11.4795 48.4561 11.2061L46 9.39453L43.5342 11.2061C43.1582 11.4795 42.8115 11.5479 42.5332 11.333Z"
                      fill="#EE8834"
                    />
                    <path
                      d="M55.5332 11.333C55.2598 11.123 55.2012 10.7764 55.3477 10.3467L56.3242 7.45117L53.8389 5.67383C53.4629 5.40527 53.2969 5.09277 53.4092 4.75586C53.5215 4.43359 53.834 4.27246 54.293 4.27734L57.3398 4.30176L58.2676 1.3916C58.4092 0.947266 58.6484 0.698242 59 0.698242C59.3467 0.698242 59.5859 0.947266 59.7275 1.3916L60.6553 4.30176L63.6973 4.27734C64.1611 4.27246 64.4736 4.43359 64.5859 4.76074C64.6982 5.09277 64.5322 5.40527 64.1562 5.67383L61.6709 7.45117L62.6475 10.3467C62.7939 10.7764 62.7354 11.123 62.4619 11.333C62.1836 11.5479 61.8369 11.4795 61.4561 11.2061L59 9.39453L56.5342 11.2061C56.1582 11.4795 55.8115 11.5479 55.5332 11.333Z"
                      fill="#EE8834"
                    />
                  </Icon>
                </VStack>
              </HStack>
              <Text>{review.date.toString().slice(4, 15)}</Text>
            </Flex>

            <Text variant={"body"}>{review.description}</Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
