import { Icon, IconProps } from "@chakra-ui/react";

export const StaticBlob = ({ ...props }: IconProps) => {
  return (
    <Icon {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_183_584)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M216.412 429.931C224.496 364.001 241.236 296.2 292.54 254.01C339.338 215.526 404.802 231.942 465.044 225.469C534.41 218.017 604.366 181.266 666.038 213.879C741.527 253.8 817.642 325.05 814.738 410.396C811.875 494.534 724.181 546.61 653.176 591.84C601.102 625.011 538.83 613.803 478.084 624.845C396.329 639.707 308.589 716.234 241.95 666.596C175.799 617.321 206.373 511.804 216.412 429.931Z"
          fill="#9747FF"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_183_584"
          x="0.578857"
          y="0.591064"
          width="1014.24"
          height="882.54"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="100"
            result="effect1_foregroundBlur_183_584"
          />
        </filter>
      </defs>
    </Icon>
  );
};
