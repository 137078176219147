import React from "react";
import styles from "./styles.module.css";
import { Flex, FlexProps } from "@chakra-ui/react";

export const Blob = ({ ...props }: FlexProps) => {
  return (
    <Flex
      display={{ base: "flex", md: "none" }}
      id="blob"
      position={"absolute"}
      w={"100vw"}
      h={"100%"}
      zIndex={props.zIndex}
      top={props.top}
      left={props.left}
    >
      <div className={styles.container}>
        <div className={styles.blobs}>
          <div className={`${styles.blob} ${styles.a}`}>a</div>
          <div className={`${styles.blob} ${styles.b}`}>b</div>
          <div className={`${styles.blob} ${styles.c}`}>c</div>
        </div>
      </div>
    </Flex>
  );
};
