import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { Form, Formik } from "formik";
import { useRef, useState } from "react";
import * as Yup from "yup";
import iPhoneImg from "../../images/iPhoneContacts.png";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

export const Contacts = () => {
  const [input, setInput] = useState("");
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement | undefined>(undefined);

  const handleInputChange = (e: any) => setInput(e.target.value);

  const validateEmail = (input: string) => {
    if (input.length < 5) {
      return false;
    }
    return input.match(
      //eslint-disable-next-line
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  };

  const isValid = validateEmail(input);

  const sendEmailToEmailJs = async () => {
    const templateParams = {
      email: input,
    };

    let response = await fetch("https://api.emailjs.com/api/v1.0/email/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({
        service_id: "service_feyk84e",
        template_id: "all_about_template",
        user_id: "n_MMrH57j0sk__IDV",

        template_params: templateParams,
      }),
    });

    if (response.ok) {
      setisLoading(false);
      setSuccessModal(true);
      setTimeout(() => {
        setSuccessModal(false);
      }, 3000);
    } else {
      console.log("err");
    }
  };

  const sendEmailToUser = async () => {
    axios
      .post("https://api.mailgun.net/v3/allaboutyou.app/messages", {
        email: input,
        subject: "Title of the test email",
        message: "Body of the test email",
      })
      .then((response) => {
        console.log("Email sent:", response.data);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const handleSubmit = (e: any) => {
    setisLoading(true);
    e.preventDefault();

    sendEmailToEmailJs();
    sendEmailToUser();

    const postData = new FormData();
    postData.append("Email", input);
    postData.append("Timestamp", new Date().toLocaleString());

    fetch(
      `https://script.google.com/macros/s/AKfycbw2OeUXV_bxA1fBRQ6PQSIu7sVqwWM7LafHiZS0mM44ENwdukx6V9lAJoaC7VQg43gUEQ/exec`,
      {
        method: "POST",
        body: postData,
      },
    ).catch((err) => console.log(err));
  };

  return (
    <>
      <Flex
        position={"relative"}
        id={"contacts"}
        direction={{ base: "column", md: "row" }}
        w={{ base: "100%", md: "80vw" }}
        h={"100%"}
        p={{ base: "32px 0 32px", md: "0", xl: "10vh 0 10vh" }}
        gap={{ base: "32px", md: "60px" }}
      >
        <Flex
          w={{ base: "100%", md: "50vw" }}
          direction={"column"}
          gap={{ base: "10px", md: "32px" }}
        >
          <Text variant={"h2"}>
            Can't wait to dive in?
            <br /> Join the waitlist to get early access.
          </Text>
          <Flex
            w={{ base: "100%", md: "455px", lg: "50%" }}
            direction={"column"}
            border={"1px solid rgba(193, 223, 224, 0.4)"}
            borderRadius={"16px"}
            bg={"#030512"}
            boxShadow={"0px 20px 40px 0px rgba(1, 22, 56, 0.25)"}
            p={"16px"}
            gap={"16px"}
          >
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({ errors, touched }) => {
                return (
                  <Form
                    ref={formRef as any}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                    className="form"
                    onSubmit={handleSubmit}
                  >
                    <FormControl isInvalid={!!errors.email && touched.email}>
                      <FormLabel>
                        <Text variant={"body"}>
                          Leave your email to be among the first to gain access
                          to our enchanting game
                        </Text>
                      </FormLabel>
                      <Input
                        id="email"
                        type="email"
                        name="email"
                        value={input}
                        onChange={handleInputChange}
                        placeholder="e-mail@waitlist.com"
                        color={"#fff"}
                        border={"1px solid rgba(193, 223, 224, 0.4)"}
                      />
                      {!isValid ? (
                        <FormErrorMessage>
                          <Text color={"red"} variant={"body"}>
                            Email is required.
                          </Text>
                        </FormErrorMessage>
                      ) : (
                        <FormHelperText
                          display={input.length > 1 ? "none" : "flex"}
                        >
                          Please enter your e-mail.
                        </FormHelperText>
                      )}

                      <Button
                        w={"100%"}
                        m={"16px auto 0"}
                        variant={"secondary"}
                        fontSize={{ base: "18px", xl: "24px" }}
                        fontWeight={700}
                        type="submit"
                        isDisabled={!validateEmail(input)}
                        cursor={"pointer"}
                      >
                        <Text cursor={"pointer"} variant={"h3"}>
                          Dive into Your Journey
                        </Text>
                      </Button>
                    </FormControl>
                  </Form>
                );
              }}
            </Formik>
          </Flex>

          {isLoading && (
            <Flex
              id="loader"
              position={"absolute"}
              left={0}
              top={0}
              w={"100%"}
              h={"100%"}
              backgroundColor={"transparent"}
              justifyContent={"center"}
              alignItems={"center"}
              zIndex={10000}
            >
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Flex>
          )}
        </Flex>

        <Flex
          w={"fit-content"}
          position={"relative"}
          scale={{ base: "none", xl: 1.5, "2xl": 2 }}
        >
          <Image
            fit={"cover"}
            position={"relative"}
            zIndex={200}
            display={{ base: "none", md: "flex" }}
            src={iPhoneImg}
          />
          <Icon
            position={"absolute"}
            top={0}
            right={"-60%"}
            width="967px"
            height="500px"
            viewBox="0 0 967 621"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            zIndex={100}
            filter={"blur(100px)"}
          >
            <g filter="url(#filter0_f_2030_333)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M557.824 324.878C558.649 358.484 539.642 386.143 523.408 415.58C504.263 450.296 494.82 497.958 456.588 508.449C417.549 519.161 378.72 490.724 348.754 463.506C322.903 440.025 308.817 406.888 306.417 372.047C304.286 341.114 319.221 313.474 336.651 287.831C354.153 262.082 375.126 239.942 404.047 228.415C441.127 213.637 484.682 194.146 518.496 215.358C552.655 236.785 556.835 284.566 557.824 324.878Z"
                fill="#9747FF"
              />
            </g>
            <g opacity="0.4" filter="url(#filter1_f_2030_333)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M174.183 251.337C211.317 204.757 280.337 213.057 338.892 202.109C400.79 190.536 465.393 155.257 519.959 186.687C577.317 219.727 598.986 290.955 607.168 356.641C615.087 420.212 612.161 494.61 563.506 536.283C518.195 575.092 450.995 544.576 391.419 541.43C350.599 539.275 311.774 539.373 275.229 521.062C229.045 497.921 178.712 474.144 160.59 425.771C139.321 368.995 136.39 298.744 174.183 251.337Z"
                fill="#EAC0C8"
              />
            </g>
            <g opacity="0.4" filter="url(#filter2_f_2030_333)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1026.63 498.19C982.804 538.541 915.866 519.78 856.325 521.642C793.385 523.61 724.146 548.591 675.031 509.183C623.402 467.758 612.884 394.053 614.847 327.889C616.746 263.854 631.019 190.78 685.477 157.04C736.191 125.62 797.932 166.057 856.325 178.279C896.335 186.653 934.718 192.495 968.032 216.182C1010.13 246.115 1056.24 277.312 1066.74 327.889C1079.08 387.249 1071.23 457.122 1026.63 498.19Z"
                fill="#EAC0C8"
              />
            </g>
            <g filter="url(#filter3_f_2030_333)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M767.415 181.177C801.833 192.81 836.294 209.286 853.209 241.438C868.638 270.766 852.595 304.011 849.389 336.995C845.698 374.974 857.634 416.514 833.335 445.934C803.591 481.946 756.999 514.822 711.608 503.814C666.859 492.961 648.679 440.223 632.318 397.182C620.319 365.616 633.219 333.504 634.033 299.744C635.128 254.309 603.857 198.838 637.825 168.643C671.544 138.669 724.675 166.732 767.415 181.177Z"
                fill="#9747FF"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_2030_333"
                x="217.73"
                y="117.526"
                width="428.603"
                height="481.72"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="44.2416"
                  result="effect1_foregroundBlur_2030_333"
                />
              </filter>
              <filter
                id="filter1_f_2030_333"
                x="0.465515"
                y="29.3761"
                width="754.657"
                height="671.013"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="72.2951"
                  result="effect1_foregroundBlur_2030_333"
                />
              </filter>
              <filter
                id="filter2_f_2030_333"
                x="470.038"
                y="0.744995"
                width="746.548"
                height="675.098"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="72.2951"
                  result="effect1_foregroundBlur_2030_333"
                />
              </filter>
              <filter
                id="filter3_f_2030_333"
                x="512.558"
                y="45.9586"
                width="456.637"
                height="569.404"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="54.6948"
                  result="effect1_foregroundBlur_2030_333"
                />
              </filter>
            </defs>
          </Icon>
        </Flex>
      </Flex>

      <Modal isOpen={successModal} onClose={() => setSuccessModal(false)}>
        <ModalOverlay />
        <ModalContent
          justifyContent={"center"}
          margin={"auto"}
          w={{ base: "90vw", md: "fit-content" }}
          alignItems={"center"}
          p={"20px"}
          borderRadius={"16px"}
          border={"1px solid rgba(193, 223, 224, 0.4)"}
          background={"#030512"}
        >
          <ModalHeader color={"#fff"}>
            <Text variant={"h3"}>Congratulations!</Text>
          </ModalHeader>
          <ModalCloseButton fill={"#fff"} color={"#fff"} />
          <ModalBody pb={6}>
            <Text textAlign={"center"} variant={"body"}>
              Your application has been successfully submitted
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
