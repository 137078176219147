import { Flex } from "@chakra-ui/react";
import "./App.css";
import { Benefits } from "./components/Benefits/Benefits";
import { CTA } from "./components/CTA/CTA";
import { Contacts } from "./components/Contacts/Contacts";
import { Footer } from "./components/Footer/Footer";
import { Hero } from "./components/Hero/Hero";
import { HowItWorks } from "./components/HowItWorks/HowItWorks";
import { Reviews } from "./components/Reviews/Reviews";

export enum PageState {
  Welcome,
  Onboarding,
  Quiz,
}

function App() {
  return (
    <Flex
      w={"100%"}
      h={"100%"}
      background={"BgColor"}
      justify={"center"}
      alignItems={"center"}
      direction={"column"}
      p={{ base: "0 16px 0", md: "0 120px 0" }}
      overflow={"hidden"}
    >
      <Hero />
      <CTA />
      <HowItWorks />
      <Reviews />
      <Benefits />
      <Contacts />
      <Footer />
    </Flex>
  );
}

export default App;
