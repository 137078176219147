import { Flex, Text } from "@chakra-ui/react";
import React from "react";

export const Footer = () => {
  return (
    <Flex
      w={"100vw"}
      direction={{ base: "column", md: "row" }}
      p={"40px"}
      gap={"16px"}
      justify={"center"}
      textAlign={"center"}
      zIndex={500}
      bgColor={"#030512"}
    >
      <Text>AI powered</Text>
      <Text>© 2024 All rights reserved.</Text>
    </Flex>
  );
};
