import { Flex, Icon, Image, Text } from "@chakra-ui/react";
import ctaBg from "../../images/ctaBg.webp";

export const CTA = () => {
  return (
    <Flex
      w={{ base: "100%", md: "960px", lg: "66vw" }}
      direction={{ base: "column", md: "row" }}
      p={{ base: "32px 0 32px", md: "60px 0 60px" }}
      background={"#030512"}
      position={"relative"}
    >
      {/* <Blob top={"80%"} left={"0px"} /> */}
      <Icon
        position={"absolute"}
        left={{ base: "0px", md: "-20%" }}
        top={{ base: "-50%", md: "-20%" }}
        transform={"rotate(-90deg)"}
        xmlns="http://www.w3.org/2000/svg"
        width={{ base: "694px", md: "694px" }}
        height={{ base: "100%", md: "694px" }}
        viewBox="0 0 657 694"
        fill="#EAC0C8"
        zIndex={100}
      >
        <g opacity="0.4" filter="url(#filter0_f_183_602)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M352.268 490.045C319.93 495.409 285.543 495.549 258.593 476.897C233.253 459.358 227.864 426.87 218.552 397.497C208.519 365.848 194.287 334.806 203.428 302.885C215.296 261.449 231.98 205.806 274.824 201.018C318.338 196.156 332.473 261.461 369.828 284.292C405.097 305.848 462.74 290.133 481.162 327.131C500.019 365.001 476.181 411.977 449.928 445.159C426.821 474.365 389.011 483.95 352.268 490.045Z"
            fill="#EAC0C8"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_183_602"
            x="0.49353"
            y="0.760559"
            width="687.435"
            height="692.415"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="100"
              result="effect1_foregroundBlur_183_602"
            />
          </filter>
        </defs>
      </Icon>
      <Icon
        position={"absolute"}
        right={"-20%"}
        top={"-20%"}
        transform={"rotate(165deg)"}
        xmlns="http://www.w3.org/2000/svg"
        width="657px"
        height="694px"
        viewBox="0 0 657 694"
        fill="#EAC0C8"
      >
        <g opacity="0.4" filter="url(#filter0_f_183_602)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M352.268 490.045C319.93 495.409 285.543 495.549 258.593 476.897C233.253 459.358 227.864 426.87 218.552 397.497C208.519 365.848 194.287 334.806 203.428 302.885C215.296 261.449 231.98 205.806 274.824 201.018C318.338 196.156 332.473 261.461 369.828 284.292C405.097 305.848 462.74 290.133 481.162 327.131C500.019 365.001 476.181 411.977 449.928 445.159C426.821 474.365 389.011 483.95 352.268 490.045Z"
            fill="#EAC0C8"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_183_602"
            x="0.49353"
            y="0.760559"
            width="687.435"
            height="692.415"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="100"
              result="effect1_foregroundBlur_183_602"
            />
          </filter>
        </defs>
      </Icon>

      <Flex
        w={"100%"}
        direction={{ base: "column", md: "row" }}
        gap={{ base: "16px", md: "36px" }}
        zIndex={2000}
        background={"#030512"}
        alignItems={"center"}
        borderRadius={"16px"}
        boxShadow={"0px 20px 40px 0px rgba(1, 22, 56, 0.25);"}
      >
        <Text
          w={"100%"}
          textAlign={{ base: "center", md: "left" }}
          ml={{ base: "0px", md: "36px" }}
          color={"#fff"}
          variant={"body"}
          fontWeight={700}
          zIndex={200}
          order={{ base: 2, md: 1 }}
          p={{ base: "0 16px 24px", md: "3%", "2lg": "10%", xl: "12%" }}
        >
          For the first time in history, a game tailored to every player. Your
          appearance, your choice, your destiny.
        </Text>
        <Image
          zIndex={200}
          borderRadius={{ base: "16px", lg: "32px" }}
          src={ctaBg}
          height={{ base: "auto", xl: "600px", "2xl": "800px" }}
          objectFit={"contain"}
          order={{ base: 1, md: 2 }}
        />
      </Flex>
    </Flex>
  );
};
