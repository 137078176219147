import { Button, Divider, Flex, Icon, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-scroll";
import gif from "../../images/Scene14.gif";

const StepsData = [
  {
    title: "Step 1",
    text: "Upload your photos",
  },
  {
    title: "Step 2",
    text: "Select the genre of your adventure: fantasy, cyberpunk, space opera, and many more",
  },
  {
    title: "Step 3",
    text: "Define the key characteristics of your character",
  },
  {
    title: "Step 4",
    text: "Start playing and make decisions that shape the storyline",
  },
];

export const HowItWorks = () => {
  return (
    <Flex
      w={"100vw"}
      h={"100%"}
      gap={"115px"}
      p={{ base: "32px 16px 0", md: "60px 0 0 20%" }}
      mb={{ base: "0", md: "10vh" }}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        w={{ base: "100%", md: "100%" }}
        h={"auto"}
        justifyContent={"space-between"}
      >
        <Flex w={{ base: "100%", md: "67%" }} direction={"column"}>
          <Text variant={"h2"} mb={{ base: "16px", md: "60px" }}>
            How It Works?
          </Text>
          <Flex
            w={"100%"}
            direction={"column"}
            gap={{ base: "16px", md: "32px" }}
            position={"relative"}
          >
            <Stack
              direction={"column"}
              position={"absolute"}
              top={0}
              left={0}
              w={"100%"}
              h={"100%"}
              pl={"10%"}
            >
              <Divider
                orientation="vertical"
                w={"4px"}
                zIndex={100}
                background={"#C1DFE066"}
              />
            </Stack>
            <Icon
              id={"icon"}
              position={"absolute"}
              left={"30%"}
              top={"-60%"}
              transform={"rotate(-90deg)"}
              xmlns="http://www.w3.org/2000/svg"
              width={{ base: "694px", md: "1059px" }}
              height={{ base: "100%", md: "1080px" }}
              viewBox="0 0 1059 1201"
              fill="#EAC0C8"
              zIndex={100}
            >
              <g opacity="0.4" filter="url(#filter0_f_2022_590)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M168.148 292.975C227.662 245.734 303.442 242.373 378.657 231.588C467.362 218.869 566.214 171.018 637.193 225.719C709.671 281.574 711.224 390.347 702.909 481.472C695.737 560.083 655.125 630.611 595.529 682.375C542.617 728.333 472.413 739.015 402.414 742.477C332.127 745.952 263.627 738.254 203.455 701.763C126.309 654.978 31.845 602.927 24.0813 513.038C16.2383 422.232 96.7591 349.64 168.148 292.975Z"
                  fill="#9747FF"
                />
              </g>
              <g opacity="0.4" filter="url(#filter1_f_2022_590)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M703.971 325.885C764.718 352.755 824.744 388.45 850.229 449.79C873.476 505.742 838.768 563.625 827.48 623.153C814.482 691.696 829.35 769.307 780.243 818.86C720.133 879.516 629.86 931.679 549.03 904.132C469.343 876.975 444.958 777.942 422.28 696.867C405.648 637.408 434.446 581.069 441.507 519.732C451.01 437.182 403.239 331.01 470.08 281.643C536.431 232.639 628.535 292.519 703.971 325.885Z"
                  fill="#9747FF"
                />
              </g>
              <g opacity="0.4" filter="url(#filter2_f_2022_590)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M423.358 993.347C357.884 1004.93 288.14 1005.96 233.073 968.718C181.295 933.697 169.658 867.92 150.132 808.545C129.093 744.571 99.5503 681.918 117.398 616.976C140.566 532.674 173.196 419.45 259.99 408.807C348.144 397.998 378.234 530.147 454.496 575.641C526.501 618.596 643.075 585.468 681.246 660.109C720.316 736.509 672.989 832.307 620.463 900.181C574.231 959.921 497.75 980.186 423.358 993.347Z"
                  fill="#EAC0C8"
                />
              </g>
              <defs>
                <filter
                  id="filter0_f_2022_590"
                  x="-176.45"
                  y="0.0668945"
                  width="1082.8"
                  height="943.164"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="100"
                    result="effect1_foregroundBlur_2022_590"
                  />
                </filter>
                <filter
                  id="filter1_f_2022_590"
                  x="217.184"
                  y="63.0977"
                  width="840.894"
                  height="1048.59"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="100"
                    result="effect1_foregroundBlur_2022_590"
                  />
                </filter>
                <filter
                  id="filter2_f_2022_590"
                  x="-88.0974"
                  y="208.182"
                  width="983.725"
                  height="992.286"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="100"
                    result="effect1_foregroundBlur_2022_590"
                  />
                </filter>
              </defs>
            </Icon>

            {StepsData.map((step, index) => (
              <Flex
                direction={"column"}
                key={index}
                w={"100%"}
                borderRadius={"16px"}
                border={"1px solid rgba(193, 223, 224, 0.4)"}
                background={"#030512"}
                boxShadow={"0px 30px 300px -10px #9747FF"}
                p={{ base: "24px 16px", md: "32px 24px" }}
                zIndex={200}
                gap={"16px"}
              >
                <Text variant={"h4"}>{step.title}</Text>
                <Text variant={"body"}>{step.text}</Text>
              </Flex>
            ))}
          </Flex>
          <Link to="contacts">
            <Button mt={"60px"} variant={"primary"} zIndex={200}>
              Start Your Adventure
            </Button>
          </Link>
        </Flex>
        <Flex
          zIndex={1000}
          w={"100%"}
          h={{ base: "50vh", md: "100%" }}
          bgImage={gif}
          backgroundSize={"cover"}
          backgroundPosition={"center"}
        />
      </Flex>
    </Flex>
  );
};
