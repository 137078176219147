import { Button, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-scroll";
import { EnLangIcon } from "src/icons/en-lang-icon";
import heroVideo from "../../images/heroVideo.mp4";
import { Blob } from "../Blob/Blob";

export const Hero = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const heroRef = useRef<HTMLDivElement | null>(null);
  const [heroHeight, setHeroHeight] = useState<any>("100dvh");

  const handleTryButton = () => {};

  for (let i = 0; i < 10; i++) {}

  useEffect(() => {
    if (!heroRef.current) {
      return;
    }

    setHeroHeight(heroRef?.current?.clientHeight);
  }, []);

  return (
    <Flex
      ref={heroRef}
      position={"relative"}
      w={"100vw"}
      h={{ base: heroHeight, md: "100dvh" }}
      bgImage={{ base: "none", md: "none" }}
      bgSize={"cover"}
      bgRepeat={"no-repeat"}
      p={{ base: "0 16px 20%", md: "80px 60px" }}
      direction={"column"}
      justifyContent={{ base: "flex-end", md: "center" }}
    >
      <Flex
        w={"100%"}
        h={"100%"}
        position={"absolute"}
        left={0}
        top={0}
        zIndex={200}
        background={
          "linear-gradient(270deg, rgba(0, 0, 0, 0.00) 56%, rgba(0, 0, 0, 0.70) 77.5%, rgba(0, 0, 0, 0.90) 100%)"
        }
      />
      <video
        src={heroVideo}
        autoPlay
        muted
        loop
        playsInline
        controls={false}
        style={{
          width: "100%",
          height: isMobile ? "50%" : "100%",
          objectFit: "cover",
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: 100,
        }}
      />

      <EnLangIcon
        position={"absolute"}
        w={{ base: "40px", xl: "120px" }}
        h={{ base: "40px", xl: "120px" }}
        right={{ base: "16px", md: "60px" }}
        top={{ base: "16px", md: "80px" }}
        zIndex={400}
        cursor={"pointer"}
        _hover={{
          opacity: 0.5,
        }}
      />

      <Blob zIndex={50} top={"-5%"} left={"0px"} />
      <Flex
        maxW={{ base: "100%", md: "38%", "2xl": "37%" }}
        direction={"column"}
        gap={{ base: "16px", md: "36px" }}
        zIndex={250}
      >
        <Text w={{ base: "196px", md: "100%" }} variant={"h1"}>
          ALL ABOUT YOU
        </Text>
        <Text variant={"h2"}>
          Welcome to a world where you are the hero of your story!
        </Text>
        <Text variant={"subtitle"} alignSelf={"stretch"}>
          Have you ever wished to see yourself as the protagonist of an epic
          saga, navigating through enchanted lands or futuristic cities? Your
          adventure starts now!
        </Text>
        <Link to={"contacts"}>
          <Button
            w={{ base: "100%", md: "448px", lg: "100%" }}
            height={{ base: "52px", md: "61px", xl: "72px", "2xl": "120px" }}
            variant={"primary"}
            mt={{ base: "24px", md: 0 }}
            justifyContent={"center"}
            fontWeight={700}
            onClick={handleTryButton}
          >
            Try now
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
};
