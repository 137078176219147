import { Flex, Image, SimpleGrid, Text } from "@chakra-ui/react";
import character from "../../images/character.webp";
import genre from "../../images/genre.webp";
import setting from "../../images/setting.webp";
import story from "../../images/story.webp";
import { StaticBlob } from "../StaticBlob/StaticBlob";

const BenefitsData = [
  {
    image: setting,
    title: "Diverse Worlds Exploration",
    text: "Dive into a variety of worlds, from fantastical realms to historic epochs, each brimming with unique characters and gripping narratives. Explore the whimsy of enchanted forests, the stark beauty of dystopian cities, or the intricate politics of ancient empires. Immerse yourself in the richness of these diverse settings and discover the secrets they hold.",
    placement: 2,
  },
  {
    image: genre,
    title: "Genre Customization",
    text: "Select a genre that aligns with your personal tastes Whether you prefer the heart-pounding suspense of thrillers, the intellectual challenge of mysteries, the emotional depth of dramas, or the light-hearted escape of comedies, there’s a genre waiting to match your mood and interests. Dive into a book, film, or game that resonates with your personality and current state of mind.",
    placement: 1,
  },
  {
    image: character,
    title: "Self-Expression Through Your Character",
    text: "Express yourself through your character, making them truly yours by customizing their appearance, abilities, and backstory. Tailor their dialogue choices to reflect your own values and decisions, allowing for a personalized journey through the narrative. This level of customization not only enhances your connection to the story but also deepens your engagement with the evolving plot and relationships within the game or story world.",
    placement: 2,
  },
  {
    image: story,
    title: "Dynamic Story Outcomes",
    text: "Experience different story endings based on the decisions you make, highlighting the impact of your choices and adding a layer of replayability to your adventure. Each decision can lead to new opportunities, relationships, and challenges, ultimately culminating in a variety of possible conclusions that reflect the path you've taken. This dynamic storytelling approach encourages exploration and experimentation, rewarding you with unique outcomes each time you play or read.",
    placement: 2,
  },
];

export const Benefits = () => {
  return (
    <Flex
      direction={"column"}
      w={{ base: "100%", lg: "83vw" }}
      h={"100%"}
      p={{ base: "32px 0 32px", md: "60px 0 60px" }}
      gap={{ base: "32px", md: "60px" }}
      position={"relative"}
    >
      <Text variant={"h2"}>Benefits</Text>
      <SimpleGrid
        w={"100%"}
        gridTemplateColumns={"1fr"}
        gridTemplateRows={"auto"}
        gap={"32px"}
      >
        <StaticBlob
          zIndex={100}
          id={"desktopRight"}
          position={"absolute"}
          top={{ base: "-9%", md: "-6%" }}
          left={{ base: "9", md: "33%" }}
          transform={"rotate(-180deg)"}
          width={{ base: "120%", md: "100%" }}
          height="884"
          viewBox="0 0 841 884"
        />
        <StaticBlob
          zIndex={100}
          id={"desktopRight"}
          position={"absolute"}
          top={{ base: "15%", md: "15%" }}
          left={{ base: "-3%", md: "-20%" }}
          transform={"rotate(-180deg)"}
          width={{ base: "120%", md: "100%" }}
          height="884"
          viewBox="0 0 841 884"
        />
        <StaticBlob
          zIndex={100}
          id={"desktopRight"}
          position={"absolute"}
          bottom={{ base: "23%", md: "10%" }}
          left={{ base: "-4%", md: "40%" }}
          transform={"rotate(-180deg)"}
          width={{ base: "120%", md: "100%" }}
          height="884"
          viewBox="0 0 841 884"
          filter={"blur(60px)"}
        />
        <StaticBlob
          zIndex={100}
          id={"desktopRight"}
          position={"absolute"}
          bottom={{ base: "-6%", md: "10%" }}
          left={{ base: "-4%", md: "40%" }}
          transform={"rotate(-180deg)"}
          width={{ base: "120%", md: "100%" }}
          height="884"
          viewBox="0 0 841 884"
          filter={"blur(60px)"}
        />
        <StaticBlob
          zIndex={100}
          id={"desktopRight"}
          position={"absolute"}
          bottom={{ base: "-6%", md: "-10%" }}
          left={{ base: "-4%", md: "-20%" }}
          transform={"rotate(-180deg)"}
          width={{ base: "120%", md: "100%" }}
          height="884"
          viewBox="0 0 841 884"
          filter={"blur(60px)"}
        />
        {BenefitsData.map((item, index) => {
          return (
            <Flex
              key={index}
              borderRadius={"16px"}
              p={{ base: "24px 0", md: "32px 0" }}
              w={"100%"}
              h={"100%"}
              gap={{ base: "32px", md: "64px" }}
              textAlign={"center"}
              wrap={"nowrap"}
              zIndex={200}
              direction={{ base: "column", md: "row" }}
              justifyContent={"space-between"}
            >
              <Flex
                textAlign={"left"}
                gap={{ base: "16px", md: "26px" }}
                w={{ base: "100%", md: "37%" }}
                direction={"column"}
                justifyContent={"center"}
                order={{ base: 2, md: index === 0 || index === 2 ? 1 : 2 }}
                flex={1}
              >
                <Text maxW={"100%"} variant={"h4"}>
                  {item.title}
                </Text>
                <Text maxW={"100%"} variant={"body"}>
                  {item.text}
                </Text>
              </Flex>

              {/* <Flex
                border={"1px solid rgba(193, 223, 224, 0.4)"}
                borderRadius={"16px"}
                w={{ base: "100%", md: "45vw" }}
                bgImage={item.image}
                bgSize={"cover"}
                order={{ base: 1, md: "" }}
                flex={1}
              /> */}
              <Image
                w={{ base: "100vw", md: "40vw" }}
                height={"auto"}
                src={item.image}
                order={{ base: 1, md: "" }}
                border={"1px solid rgba(193, 223, 224, 0.4)"}
                borderRadius={"16px"}
              />
            </Flex>
          );
        })}
      </SimpleGrid>
    </Flex>
  );
};
